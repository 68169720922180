import { Form, FormItemProps } from 'antd'
import { FC } from 'react'

const UFormItem: FC<FormItemProps> = (props) => {
  return (
    <Form.Item
      style={{ margin: 0 }}
      {...props}
    />
  )
}

export default UFormItem
